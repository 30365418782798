import { isDefined } from '../helpers'
import moment from '../moment'

export const isValidDate = <T extends string | Date | undefined | null>(date: T): date is NonNullable<T> => {
  if (date === null || date === undefined) {
    return false
  }
  return moment(date).isValid()
}

/** Truncates the received date to the start of the day in UTC */
export function dateToStartOfDay(date: Date) {
  return moment(date).startOf('day').toDate()
}

/** Converts the received date to the end of the day in UTC */
export function dateToEndOfDay(date: Date) {
  return moment(date).endOf('day').toDate()
}

export function yesterday() {
  return moment().subtract(1, 'day').toDate()
}

export function getCurrentTime() {
  return moment().toDate()
}

export function today() {
  return new Date(Date.now())
}

export function isToday(date: Date) {
  return moment(date).isSame(moment(), 'day')
}

/** Returns true if provided date is sunday or saturday */
export function isWeekend(date: Date) {
  const dayOfWeek = moment(date).day()
  return dayOfWeek === 0 || dayOfWeek === 6
}

/**
 * @param date The date to start counting from
 * @param amountOfDays The number of working days
 * @returns Array of working days before provided date (including provided one if it's a working day) with a length of @amountOfDays
 */
export function getWorkingDaysBeforeDate(date: Date, amountOfDays: number) {
  const dates: Date[] = []
  let currentDate = date
  while (dates.length < amountOfDays) {
    if (!isWeekend(currentDate)) {
      dates.push(currentDate)
    }
    currentDate = moment(currentDate).subtract(1, 'day').toDate()
  }
  return dates
}

/**
 * Returns the current date minus the provided years
 * @param years Number of years to subtract from the current date
 * @returns Date object with the current date minus the amount of years passed
 */
export function getYearsAgo(years: number) {
  return moment().subtract(years, 'years').toDate()
}

/**
 * @returns true if provided date is at least 18 years old
 * @param date
 */
export function isGt18YearsOld(date: Date) {
  return moment(date).isSameOrBefore(getYearsAgo(18))
}

export function isGtThanToday(date: Date): boolean {
  return moment(date).isAfter(moment().toDate())
}

export function addDays(date: Date, days: number) {
  return moment(date).add(days, 'days').toDate()
}

export function addTimeToDate({
  date,
  amount,
  unit,
}: {
  date?: Date
  amount: number
  unit: moment.unitOfTime.DurationConstructor
}) {
  if (!isDefined(date)) {
    return moment().add(amount, unit).toDate()
  }
  return moment(date).add(amount, unit).toDate()
}

type BusinessDaysDifferenceGranularity = 'minute' | 'second' | 'millisecond'

export function getBusinessDaysDifference(
  startDate: Date,
  endDate: Date,
  // milliseconds is the default granularity in moment
  granularity: BusinessDaysDifferenceGranularity = 'millisecond'
): number {
  const start = moment(startDate)
  const end = moment(endDate)
  let days = 0

  while (start.isBefore(end, granularity)) {
    start.add(1, 'days')

    if (start.isAfter(end, granularity)) {
      break
    }

    if (start.day() === 0 || start.day() === 6) {
      continue
    }

    days++
  }

  return days
}

export function addBusinessDays(date: Date, numberOfDays: number) {
  let businessDays = 0
  let currentDate = moment(date)
  while (businessDays < numberOfDays) {
    currentDate = currentDate.add(1, 'day')
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      businessDays++
    }
  }
  return currentDate.toDate()
}

export function substractBusinessDays(date: Date, numberOfDays: number) {
  let businessDays = 0
  let currentDate = moment(date)
  while (businessDays < numberOfDays) {
    currentDate = currentDate.subtract(1, 'day')
    if (currentDate.day() !== 0 && currentDate.day() !== 6) {
      businessDays++
    }
  }
  return currentDate.toDate()
}

/**
 * @param numberOfYears Number of years to subtract from current date (e.g. 1 for last year)
 * @returns Date object with the current date minus the @param numberOfYears
 */
export function subtractYearsFromCurrentDate(numberOfYears: number) {
  const currentDate = new Date()
  currentDate.setFullYear(currentDate.getFullYear() - numberOfYears)

  return currentDate
}

export const isDateBetween = ({ startDate, endDate, date }: { startDate: Date; endDate: Date; date?: Date }) => {
  const actualDate = moment(date)
  const startDateMoment = moment(dateToStartOfDay(startDate))
  const endDateMoment = moment(dateToEndOfDay(endDate))
  return actualDate.isBetween(startDateMoment, endDateMoment)
}

export const hasChangedDate = (newDate?: Date, oldDate?: Date): boolean => {
  const isDefinedNew = isDefined(newDate)
  const isDefinedOld = isDefined(oldDate)

  if (isDefinedNew && !isDefinedOld) {
    return true
  }
  if (isDefinedOld && isDefinedNew) {
    const diff = moment(dateToStartOfDay(newDate)).diff(dateToStartOfDay(oldDate))
    return diff !== 0
  }
  return false
}

export function timeout<T>(promise: () => Promise<T>, ms: number, message?: string) {
  return Promise.race([
    promise(),
    new Promise((_, reject) => {
      // Wait for ms seconds before rejecting the promise
      const timeoutMessage = message || `Promise timed out after ${ms} ms`
      setTimeout(() => reject(new Error(timeoutMessage)), ms)
    }),
  ])
}

export const getWeekday = (date: Date): Date => {
  const startDate = moment(date)

  // Saturday
  if (startDate.day() === 6) {
    return startDate.add(2, 'days').toDate()
  }
  // Sunday
  if (startDate.day() === 0) {
    return startDate.add(1, 'days').toDate()
  }

  return startDate.toDate()
}

export function sortDatesAsc(dates: Array<Date | undefined | null>) {
  return dates.filter(isDefined).sort((a, b) => a.getTime() - b.getTime())
}

export function sortDatesDesc(dates: Array<Date | undefined | null>) {
  return dates.filter(isDefined).sort((a, b) => b.getTime() - a.getTime())
}

/**
 * @param date Date to be validated
 * @param rest Array of dates to be compared with date param.
 * @returns Boolean indicating if a date is more recent than others.
 */
export function isNewestDate(date: Date, rest: (Date | null | undefined)[]) {
  return !rest.filter(isDefined).some((d) => d > date)
}

/**
 * Converts an ISO string to a date, ignoring the time zone offset
 * @param isoString - The ISO string to convert
 * @returns A date object
 */
export function dateFromIsoString(isoString: string) {
  const [year, month, day] = isoString.split('T')[0].split('-')
  return new Date(+year, +month - 1, +day)
}
