import React, { forwardRef, Ref, useState } from 'react'
import { Box, Center, Divider, Flex, Icon, Progress, Spinner, Tag, Text, useToast } from 'ui-lib'
import { isDefined, Money } from 'utils'

import { FundingInstallment, useGetInstallmentsLazyQuery } from '@/gql'
import { FUNDING_STATE_NAMES, FUNDING_STATUS_STYLES } from '@/src/constants/capital'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { date } from '@/src/utils/date'
import { GenericFunding } from '@/types/capital'

import { FundingCardAction, FundingInstallments } from '.'

type FundingCardProps = {
  funding: GenericFunding
  children: React.ReactNode
  defaultOpen?: boolean
  onOpen?: () => void
}

const _FundingCard = ({ funding, children, defaultOpen, onOpen }: FundingCardProps, ref: Ref<HTMLDivElement>) => {
  const toast = useToast()
  const [open, setOpen] = useState(defaultOpen ?? false)
  const { fundingId, principalAmount, totalRepayment, nextPaymentDate, loanNumber, status } = funding
  const isEarlyPaymentEnabled = useFeatureGate(StatsigFeatureGate.CAPITAL_EARLY_PAYMENTS)

  const [getInstallments, { data, loading, error }] = useGetInstallmentsLazyQuery({
    variables: {
      fundingId,
    },
    onError: (error) => {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        position: 'top',
        duration: 3000,
      })
    },
  })

  const installments = (data?.installments?.filter(Boolean) ?? []) as FundingInstallment[]
  const nextPaymentOn = isDefined(nextPaymentDate) ? date(nextPaymentDate).utc().format('MMM DD, YYYY') : null

  const toggleOpen = () => {
    const nextOpen = !open
    setOpen(nextOpen)
    getInstallments()
    if (nextOpen) {
      onOpen?.()
    }
  }

  return (
    <Flex
      ref={ref}
      direction="column"
      width="100%"
      borderRadius="12px"
      borderWidth="1px"
      borderStyle="solid"
      borderColor="border-soft"
    >
      <Flex
        direction={{ base: 'column', lg: 'row' }}
        width="100%"
        py="24px"
        cursor="pointer"
        onClick={toggleOpen}
        px="16px"
        alignItems="center"
        gap={{ base: 3, lg: 0 }}
      >
        <Flex width={{ base: '100%', lg: '50%' }} direction="row" gap={2}>
          <Flex align="start" mt={1}>
            <Icon icon="chevron-down" size="sm" transform={`${!open ? 'rotate(-90deg)' : 'none'}`} />
          </Flex>
          <Box w={['full', 'auto']}>
            <Flex gap={2} justifyContent={['space-between', 'auto']} w={['full', 'auto']}>
              <Text textStyle="title-md" textColor="text-primary">
                {loanNumber}
              </Text>
              <Tag variant={FUNDING_STATUS_STYLES[status]}>
                <Text textStyle="subheading-sm" textColor="none">
                  {FUNDING_STATE_NAMES[status] ?? status}
                </Text>
              </Tag>
            </Flex>
            {isDefined(nextPaymentOn) && !isEarlyPaymentEnabled && (
              <Text textStyle="paragraph-sm" textColor="text-soft">
                Next payment on {nextPaymentOn}
              </Text>
            )}
          </Box>
        </Flex>
        <Flex
          pl={{
            base: '24px',
            lg: 0,
          }}
          width={{ base: '100%', lg: '50%' }}
          direction="column"
        >
          <Flex direction="row">
            <Text textStyle="title-md" textColor="text-primary" flexGrow={1}>
              {Money.fromNumber(totalRepayment).toFormattedCurrencyString()}
            </Text>
            <Text textStyle="paragraph-lg" textColor="text-secondary" textAlign="right">
              of {Money.fromNumber(principalAmount).toFormattedCurrencyString()}
            </Text>
          </Flex>
          <Progress
            colorScheme="primary"
            size="xs"
            value={totalRepayment}
            max={principalAmount}
            marginTop="6px"
            borderRadius="2px"
            backgroundColor="#DDDDDD"
          />
        </Flex>
      </Flex>
      {isEarlyPaymentEnabled && <FundingCardAction funding={funding} open={open} />}
      {open &&
        (loading ? (
          <Center m={4}>
            <Spinner size="xl" />
          </Center>
        ) : isDefined(error) ? (
          <Text color="text-error" textStyle="paragraph-md" p={4} ml={6}>
            An error occurred, we could not load the installments.
          </Text>
        ) : (
          <>
            <Divider />
            {children}
            <Divider />
            <FundingInstallments installments={installments} />
          </>
        ))}
    </Flex>
  )
}

export const FundingCard = forwardRef(_FundingCard)
