import { useSession } from 'next-auth/react'
import { useFormContext } from 'react-hook-form'
import {
  Box,
  Button,
  Flex,
  Form,
  Icon,
  IconButton,
  isDefined,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Tooltip,
  useBoolean,
  useToast,
  VisuallyHiddenInput,
} from 'ui-lib'
import { sanitizeFileName } from 'utils'

import { useGetExpenseReceiptFileLazyQuery, useRemoveReceiptFromExpenseMutation } from '@/gql'
import { useAddReceiptToExpense } from '@/src/hooks/accounting'
import { FileResult, useUploadFile } from '@/src/hooks/misc'

import { ColumnComponentProps } from './types'

export interface ReceiptButtonProps extends ColumnComponentProps {
  disabled?: boolean
}

export const ReceiptButton = ({ row, disabled = false }: ReceiptButtonProps) => {
  const expense = row.original
  const { formState, setValue } = useFormContext()
  const toast = useToast()
  const { data: session } = useSession()
  const activeUser = session?.user?.internal
  const hasDocument = isDefined(expense.receipt)

  const [isLoadingReceipt, setIsLoadingReceipt] = useBoolean(false)

  const [getExpenseReceiptFile, { error: getFileError }] = useGetExpenseReceiptFileLazyQuery({
    variables: { expenseId: expense.id },
  })

  const { uploadFile } = useUploadFile({
    path: `business/${activeUser?.businessId}/expenseReceipts/${expense.id}/`,
  })

  const { addReceiptToExpense } = useAddReceiptToExpense()
  const [removeReceiptFromExpense] = useRemoveReceiptFromExpenseMutation()

  const handleAddReceiptToExpense = async (expenseId: string, receiptDocument: FileResult) => {
    const response = await addReceiptToExpense(expenseId, receiptDocument)
    if (isDefined(response)) {
      row.original.receipt = response
      setValue(`dataGridExpenses.${row.index}.receipt`, response)
      toast({
        title: 'Receipt added',
        description: 'The receipt has been successfully added to the expense',
        status: 'success',
      })
    }
  }

  const handleChangeReceipt = async (files: FileList) => {
    const sanitizedName = sanitizeFileName(files[0].name)
    const extension = files[0].name.split('.').pop()
    const newFile = new File([files[0]], `${sanitizedName}.${extension}`, { type: files[0].type })
    if (!isDefined(newFile) || !(newFile instanceof File)) {
      return
    }
    try {
      setIsLoadingReceipt.on()
      const uploadedFile = await uploadFile(newFile)
      if (isDefined(uploadedFile.error)) {
        throw uploadedFile.error
      }
      await handleAddReceiptToExpense(expense.id, uploadedFile)
    } catch (err) {
      console.error(err)
    }
    setIsLoadingReceipt.off()
  }

  const executeDownloadButton = async (fileName: string, fileAsBase64: string) => {
    const mimes: Record<string, string> = {
      pdf: 'application/pdf',
      png: 'image/png',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
    } as const
    const [, fileExtension] = fileName.split('.')
    window.open(`data:${mimes[fileExtension]};base64,${fileAsBase64}`, '_blank')
  }

  const downloadReceipt = async () => {
    setIsLoadingReceipt.on()
    try {
      const receiptPath = expense.receipt?.document?.path
      if (!isDefined(receiptPath)) {
        return
      }
      const { data: expenseReceiptFileData } = await getExpenseReceiptFile({ variables: { expenseId: expense.id } })
      const expenseReceiptFile = expenseReceiptFileData?.getExpenseReceiptFile
      if (isDefined(getFileError) || !isDefined(expenseReceiptFile)) {
        throw new Error('Error fetching receipt')
      }
      executeDownloadButton(expenseReceiptFile.fileName, expenseReceiptFile.file)
    } catch (error) {
      console.error(error)
      toast({
        title: 'Error downloading receipt',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
      })
    }
    setIsLoadingReceipt.off()
  }

  const deleteReceipt = async () => {
    setIsLoadingReceipt.on()
    try {
      await removeReceiptFromExpense({ variables: { body: { expenseId: expense.id } } })
      row.original.receipt = undefined
      setValue(`dataGridExpenses.${row.index}.receipt`, null)
      toast({
        title: 'Receipt deleted',
        description: 'The receipt has been successfully deleted',
        status: 'success',
      })
    } catch (error) {
      console.error(error)
      toast({
        title: 'Error deleting receipt',
        description: 'Something went wrong. Please try again later.',
        status: 'error',
      })
    }
    setIsLoadingReceipt.off()
  }

  return (
    <Form.Field
      id={`dataGridExpenses.${row.index}.receipt`}
      errorMessage={(formState.errors.dataGridExpenses?.at(row.index)?.receipt as any)?.message}
    >
      <Flex position="relative" justifyContent="center">
        {isLoadingReceipt && <Spinner size="xs" />}
        {!hasDocument && !isLoadingReceipt && (
          <>
            <Icon icon="add-receipt" size="md" variant="original" />
            <Tooltip label="Add receipt" hasArrow={false} openDelay={200}>
              <Box as="label" position="absolute" w="full" h="full" top="0" left="0" cursor="pointer">
                <VisuallyHiddenInput
                  disabled={disabled}
                  type="file"
                  multiple={false}
                  accept=".pdf,.png,.jpg,.jpeg"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    if (event.target.files == null || event.target.files.length === 0) {
                      return
                    }
                    handleChangeReceipt(event.target.files)
                  }}
                />
              </Box>
            </Tooltip>
          </>
        )}
        {hasDocument && !isLoadingReceipt && (
          <Tooltip label="View receipt" hasArrow={false}>
            <Menu>
              <MenuButton as={Button} variant="ghost" px={0} py={0}>
                <IconButton
                  variant="ghost"
                  icon={<Icon icon="approved-receipt" size="md" variant="original" />}
                  aria-label="Download receipt"
                />
              </MenuButton>
              <MenuList zIndex="3">
                <MenuItem onClick={downloadReceipt}>Download</MenuItem>
                <MenuItem onClick={deleteReceipt}>Delete</MenuItem>
              </MenuList>
            </Menu>
          </Tooltip>
        )}
      </Flex>
    </Form.Field>
  )
}
