import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { ActionButtons, Form, useBoolean } from 'ui-lib'
import { isDefined } from 'utils'

import { AccountingExpense } from '@/gql'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { useUpdateCardholderExpenseData } from '@/src/hooks/accounting'
import { FileResult, useInternalUser, useUploadFile } from '@/src/hooks/misc'

import { getIsDisabledReceiptForm, getReceiptValues, ReceiptFormData, schema, TEN_MB_IN_BYTES } from './utils'

interface Props {
  expense: AccountingExpense | null
  onSuccess?: () => void
  onError?: (error: Error) => void
  setFormIsDirty?: (isDirty: boolean) => void
}

export const ExpenseReceiptForm = ({ expense, onSuccess, onError, setFormIsDirty }: Props) => {
  const activeUser = useInternalUser()
  const [isLoadingReceipt, { on: startLoadingReceipt, off: stopLoadingReceipt }] = useBoolean(false)
  const { updateCardholderExpenseData } = useUpdateCardholderExpenseData()
  const isExpenseManagementEnabled = useFeatureGate(StatsigFeatureGate.RUTTER_INTEGRATION)

  const { control, register, formState, handleSubmit, setError, clearErrors, setValue, watch, resetField, reset } =
    useForm<ReceiptFormData>({
      defaultValues: {
        memo: expense?.memo ?? '',
        receipt: getReceiptValues(expense?.receipt),
      },
      resolver: zodResolver(schema),
    })

  useEffect(() => {
    if (isDefined(setFormIsDirty)) {
      setFormIsDirty(formState.isDirty)
    }
  }, [formState.isDirty, setFormIsDirty])

  const currentFile = watch('receipt')
  const receiptError = (formState.errors.receipt as unknown as { message: string })?.message

  const { uploadFile } = useUploadFile({
    path: `business/${activeUser?.businessId}/expenseReceipts/`,
  })

  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      const timer = setTimeout(() => {
        reset()
      }, 1000)

      return () => clearTimeout(timer)
    }
  }, [formState.isSubmitSuccessful, reset])

  if (!isExpenseManagementEnabled || !isDefined(expense)) {
    return null
  }

  const isDisabled = getIsDisabledReceiptForm(expense)

  const handleChangeReceipt = async (files: (File | FileResult)[]) => {
    startLoadingReceipt()
    clearErrors(undefined)
    const newFile = files[0]
    if (!isDefined(newFile) || !(newFile instanceof File)) {
      resetField('receipt', { defaultValue: null })
      stopLoadingReceipt()
      return
    }
    try {
      const uploadedFile = await uploadFile(newFile)
      if (isDefined(uploadedFile.error)) {
        throw uploadedFile.error
      }
      setValue('receipt', uploadedFile as ReceiptFormData['receipt'], { shouldDirty: true })
    } catch (err) {
      setError('receipt', { message: 'Failed to upload receipt' })
    }
    stopLoadingReceipt()
  }

  const saveCardholderExpenseData = async (data: ReceiptFormData) => {
    if (!isExpenseManagementEnabled) {
      return
    }

    const receiptDocument = expense.receipt?.document
    await updateCardholderExpenseData({
      expenseId: expense.id,
      data: {
        memo: data.memo,
        receiptDocument: {
          ...(data.receipt as FileResult),
          id: receiptDocument?.id,
        },
      },
      onError,
    })
    reset(data)
    onSuccess?.()
  }

  return (
    <Form display="flex" flexDir="column" gap="4" onSubmit={handleSubmit(saveCardholderExpenseData)}>
      <Form.Field id="memo" label="Add memo" errorMessage={formState.errors.memo?.message}>
        <Form.Input id="memo" isDisabled={isDisabled} {...register('memo')} />
      </Form.Field>
      <Controller
        control={control}
        name="receipt"
        render={({ fieldState }) => (
          <Form.FormControl isInvalid={isDefined(receiptError)}>
            <Form.Field id="receipt" label="Upload Receipt" errorMessage={receiptError}>
              <Form.FileInput
                id="receipt"
                isLoading={isLoadingReceipt}
                onFilesChange={handleChangeReceipt}
                value={isDefined(currentFile) ? ([currentFile] as unknown[] as File[]) : undefined}
                maxFiles={1}
                maxFileSize={TEN_MB_IN_BYTES}
                label="Format: PDF,PNG,JPG,JPEG"
                accept={['pdf', 'png', 'jpg', 'jpeg']}
                isInvalid={fieldState.invalid}
                onError={(type, message) => setError('receipt', { type, message })}
                isDisabled={isDisabled}
              />
            </Form.Field>
          </Form.FormControl>
        )}
      />
      {!isDisabled && (
        <ActionButtons>
          <ActionButtons.Primary
            type="submit"
            isLoading={formState.isSubmitting}
            isSuccess={formState.isSubmitSuccessful}
            isDisabled={isLoadingReceipt || !formState.isDirty}
          >
            {isDefined(expense?.memo) ? 'Update' : 'Submit'}
          </ActionButtons.Primary>
        </ActionButtons>
      )}
    </Form>
  )
}
