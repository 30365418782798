import React from 'react'
import { Box, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader } from 'ui-lib'

import { AccountStep, AmountStep, CompleteStep, ReviewStep } from '@/src/components/capital'
import HeaderStepper from '@/src/components/molecules/HeaderStepper'
import { EarlyPaymentProvider, PaymentStep, useEarlyPayment } from '@/src/contexts/capital'
import { StatsigFeatureGate, useFeatureGate } from '@/src/contexts/misc'
import { GenericFunding } from '@/types/capital'

import { StepperMobile } from '../sidebar-stepper-template'

interface BaseProps {
  isOpen: boolean
}

interface EarlyPaymentTemplateProps extends BaseProps {
  funding: GenericFunding
  children: React.ReactNode
  onClose: () => void
}

const STEPS: Record<
  PaymentStep,
  {
    key: PaymentStep
    label: string
    position: number
  }
> = {
  amount: {
    key: 'amount',
    label: 'Amount',
    position: 0,
  },
  account: {
    key: 'account',
    label: 'Account',
    position: 1,
  },
  review: {
    key: 'review',
    label: 'Confirm',
    position: 2,
  },
  complete: {
    key: 'complete',
    label: 'complete',
    position: 3,
  },
}

const STEP_KEYS = Object.keys(STEPS) as PaymentStep[]

const Content = () => {
  const { currentStep } = useEarlyPayment()
  switch (currentStep) {
    case 'amount':
      return <AmountStep />

    case 'account':
      return <AccountStep />

    case 'review':
      return <ReviewStep />

    case 'complete':
      return <CompleteStep />

    default:
      return null
  }
}

const EarlyPaymentModal = ({ isOpen }: BaseProps) => {
  const { goToStep, currentStep, confirmExit, goToPreviousStep } = useEarlyPayment()
  const activeStep = STEPS[currentStep]
  const hideSteps = currentStep === 'complete'
  return (
    <Modal isOpen={isOpen} onClose={confirmExit}>
      <ModalContent>
        {!hideSteps && (
          <>
            <ModalHeader borderColor="border-disabled" p="4">
              Make a payment
            </ModalHeader>
            <ModalCloseButton top="4" />
          </>
        )}
        <ModalBody>
          <Box width="full">
            {!hideSteps && (
              <Flex mx="-6" mt="-3" flexDir="column" display={{ base: 'none', md: 'flex' }}>
                <Box maxWidth="desktop" mx="auto" w="full" px="6">
                  <HeaderStepper
                    pb="3"
                    mx="auto"
                    alignItems="center"
                    steps={STEP_KEYS.filter((step) => step !== 'complete').map((step) => ({
                      label: STEPS[step].label,
                    }))}
                    activeStep={activeStep.position}
                    onStepClick={(step: number) => goToStep(STEP_KEYS[step])}
                    allowExit={false}
                    hideLogo={true}
                  />
                </Box>
                <Divider mb="6" w="full" />
              </Flex>
            )}
            {!hideSteps && (
              <Flex
                flexDirection="column"
                alignItems="flex-start"
                width="full"
                maxWidth="480px"
                mt="-3"
                display={{ base: 'flex', md: 'none' }}
              >
                <StepperMobile
                  activeStep={activeStep}
                  activeIndex={activeStep?.position}
                  title={activeStep?.label}
                  totalSteps={STEP_KEYS.length}
                  onClick={(step: number) => goToStep(STEP_KEYS[step])}
                  goToPreviousStep={goToPreviousStep}
                />
                <Divider mb="6" w="full" />
              </Flex>
            )}
            <Content />
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export const EarlyPaymentTemplate = ({ isOpen, onClose, children, ...contextProps }: EarlyPaymentTemplateProps) => {
  const isEarlyPaymentsEnabled = useFeatureGate(StatsigFeatureGate.CAPITAL_EARLY_PAYMENTS)

  if (!isEarlyPaymentsEnabled) {
    return null
  }

  return (
    <EarlyPaymentProvider onComplete={onClose} isOpen={isOpen} {...contextProps}>
      {isOpen && <EarlyPaymentModal isOpen={isOpen} />}
      {children}
    </EarlyPaymentProvider>
  )
}
