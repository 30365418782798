import {
  COUNTRIES_LIST,
  CountryListType,
  Currencies,
  getCountryByCode,
  getCountryNameByCode,
} from 'services/utils/countries'

import { Option } from '@/src/components/atoms/CustomAutocomplete'

export const DEFAULT_COUNTRY = 'CAN'
export const DEFAULT_COUNTRY_OPTION = {
  label: 'Canada',
  value: DEFAULT_COUNTRY,
}

export const COUNTRIES_OPTIONS = COUNTRIES_LIST.map<Option>((country) => ({
  label: country.name,
  value: country.code,
}))

export type { CountryListType }

export { getCountryNameByCode, getCountryByCode, COUNTRIES_LIST, Currencies }
