import React from 'react'
import { Box, Divider, Flex, useMediaQuery } from 'ui-lib'
import { isDefined } from 'utils'

import HeaderStepper from '@/src/components/molecules/HeaderStepper'
import { FullStepType } from '@/src/constants/steps'
import { useConfirmAction } from '@/src/contexts/misc'

import { BackHeaderTemplate } from '../back-header-template'
import { StepperMobile } from '../sidebar-stepper-template'

const confirmOptions = {
  title: 'Exit without saving?',
  description: 'You have unsaved changes. Are you sure you want to exit?',
  primaryAction: {
    label: 'Exit',
  },
}

interface Props<T> {
  allowExit?: boolean
  children: React.ReactNode
  currentStep: T
  hideSteps?: boolean
  steps: { [key: string]: FullStepType<T> }
  onStepChange?: (step: T) => void
  onExit?: () => void
  showConfirmationOnExit?: boolean
}

export const TopStepperTemplate = <T extends string>({
  currentStep,
  steps,
  hideSteps = false,
  onStepChange,
  children,
  allowExit = true,
  onExit,
  showConfirmationOnExit = true,
}: Props<T>) => {
  const confirm = useConfirmAction()

  const [isMobile] = useMediaQuery('(max-width: 768px)')

  const listOfSteps = Object.values(steps).filter((step) => !Boolean(step.skip))

  const activeStep = steps[currentStep]

  const activeIndex = listOfSteps.findIndex((step) => step.position === activeStep.position)

  const isPastStep = (step: FullStepType<T>) => step.position < activeStep.position

  const goToStep = (stepIndex: number) => {
    const step = listOfSteps[stepIndex]

    if (!isPastStep(step)) {
      return
    }

    onStepChange?.(step.key)
  }

  const handleExit = () => {
    if (showConfirmationOnExit) {
      confirm(confirmOptions).then((result) => Boolean(result) && onExit?.())
    } else {
      onExit?.()
    }
  }

  const goToPreviousStep = () => {
    if (steps[currentStep as string].position === 0) {
      handleExit()
      return
    }

    const previousStep = listOfSteps[activeIndex].previous ?? listOfSteps[activeIndex - 1].key

    if (isDefined(previousStep)) {
      onStepChange?.(previousStep)
    }
  }

  return (
    <Box width="full" minWidth="100hv" minHeight="100vh">
      {!hideSteps && !isMobile && (
        <>
          <Box maxWidth="desktop" mx="auto">
            <HeaderStepper
              display={isMobile ? { base: 'none', md: 'flex' } : 'flex'}
              px="4"
              py="6"
              alignItems="center"
              mx="auto"
              maxW="70%"
              steps={listOfSteps.map((step) => ({ label: step.label }))}
              activeStep={activeIndex}
              onStepClick={goToStep}
              onClose={handleExit}
              allowExit={allowExit}
            />
          </Box>
          <Divider />
        </>
      )}

      {!hideSteps && isMobile && (
        <BackHeaderTemplate onBackClick={handleExit}>
          <Flex
            flexDirection="column"
            alignItems="flex-start"
            marginInline="auto"
            width="full"
            maxWidth="480px"
            paddingInline={{ base: '6', md: '0' }}
          >
            <StepperMobile
              activeStep={activeStep}
              activeIndex={activeIndex}
              title={activeStep?.label}
              totalSteps={listOfSteps.length}
              onClick={goToStep}
              goToPreviousStep={goToPreviousStep}
            />
          </Flex>
        </BackHeaderTemplate>
      )}

      {children}
    </Box>
  )
}
